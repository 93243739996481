import { useContext } from "react"
import LoginContext from "../LoginContext"
import { Link, useNavigate } from "react-router-dom"

export default function Header({title}) {
    const loginContext = useContext(LoginContext)
    const navigate = useNavigate()
    return (
        <div className="row">
            <nav className="navbar bg-body-tertiary">
                <div className="container-fluid">
                    <div className="navbar-brand d-flex align-items-center" >
                        <img src="/images/logo.svg" alt="AERAS" className="align-text-top" style={{width: "2em"}} onClick={() => navigate("/reports")} />
                        <span className="ms-2">{title || "AERAS"}</span>
                    </div>
                    <div>
                        <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="navbar-toggler-icon"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link className="dropdown-item icon-link" to="/reports">
                                        <i className="bi-files me-1"></i>My Reports
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item icon-link" to="/profile">
                                        <i className="bi-map me-1"></i>My Profile
                                    </Link>
                                </li>
                                <li><hr className="dropdown-divider" /></li>
                                <li>
                                    <Link className="dropdown-item icon-link" to="https://aerasenv.com/">
                                        <i className="bi-arrow-left me-1"></i>Return to aerasenv.com
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item icon-link" to="https://aerasenv.com/account-settings/my/my-account">
                                        <i className="bi-person-circle me-1"></i>Account Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item icon-link" to="https://aerasenv.com/my-orders">
                                        <i className="bi-receipt me-1"></i>My Orders
                                    </Link>
                                </li>
                                <li><hr className="dropdown-divider" /></li>
                                <li>
                                    <button className="dropdown-item icon-link" onClick={loginContext.logout}>
                                        <i className="bi-box-arrow-left me-1"></i>Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div class="alert alert-warning" role="alert">
                <i class="bi-exclamation-diamond"></i>&nbsp;
                AERAS is now live and this beta server will be shutdown February 1, 2025. <br/><br/>
                Please click <a href="https://aerasenv.com/?showSignup=true" class="underline">Sign Up</a> and create an account using your current email address and continue using AERAS for free.
            </div>
        </div>
    )
}
